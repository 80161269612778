import React from "react";
import NavBar from "../components/NavBar";
import StoreButtons from "../components/StoreButtons";
import { images } from "../Assets";
import Footer from "../components/Footer";

const PartnerRider = () => {
  return (
    <div>
      <NavBar />
      <div className='h-auto'>
        <div className=' p-2 px-8 w-full'>
          <div className='md:flex justify-center items-center '>
            <div className='md:w-[40rem] md:h-[25rem] md:ml-10 gap-8 '>
              <div className=' flex flex-col  md:text-left  text-center mt-32 md:mt-16 mb-8 gap-4 md:gap-8'>
                <h1 className='gap-8 font-bold text-3xl md:text-6xl text-primaryBlack leading-tight md:leading-normal'>
                  Earn on Your Own <br />
                  <span className='text-[#C3990F]'>Terms</span>
                </h1>
                <p className='text-primaryBlack w-full md:w-[33rem]  text-base font-normal leading-7'>
                  Looking for a side hustle or a full time gig? Earn money on
                  your own terms by delivering happiness to our customers.
                </p>
              </div>
              <StoreButtons />
            </div>

            <div className='flex justify-center mt-5'>
              <img
                src={images.removebg}
                className='w-full md:w-[30rem] h-auto md:h-[41rem]'
                alt='hero'
              />
            </div>
          </div>
        </div>

        <div className='md:flex justify-center items-center p-4 bg-white'>
          <div className='flex relative'>
            <img
              src={images.boss}
              className='w-full md:w-[38rem] h-auto md:h-[41rem]'
              alt='hero'
            />
            <img
              src={images.card}
              className='absolute hidden md:block md:bottom-[-2rem] md:left-[8rem] h-auto w-40 md:w-56'
              alt='hero'
            />
          </div>
          <div className='mb-8 gap-4 md:gap-8'>
            <h1 className='font-bold text-2xl md:text-5xl text-primaryBlack leading-tight md:leading-normal'>
              Be Your Own Boss <br />
              <span className='text-[#C3990F]'>NowNow</span>
            </h1>
            <p className='text-primaryBlack w-full md:w-[33rem] text-base font-normal leading-7'>
              If you’re reliable and love assisting others, sign up to become a
              NowNow Angel today. Use your bicycle, motorcycle or even your feet
              to earn money on your own schedule.
            </p>
          </div>
        </div>

        <div className='bg-white  items-center justify-center p-4 md:p-8'>
          <div className=' flex flex-col items-center gap-10'>
            <h2 className='text-center text-2xl text-primaryBlack font-bold md:text-5xl md:mt-32'>
              How It <span className='text-[#C3990F]'>Works</span>
            </h2>
            <p className='text-primaryBlack text-center text-base font-normal md:w-[36rem] leading-7'>
              As a NowNow Angel (Delivery Agent), We provide you with all the
              support you need to get started.
            </p>
          </div>

          <div className='mt-10  md:flex justify-center '>
            <div className='grid grid-cols-1 md:grid-cols-3 gap-8 items-center'>
              <div className='flex md:flex-col items-center justify-between md:gap-16'>
                <img
                  src={images.downloadApp2}
                  alt='hero'
                  className='h-[8rem] w-[8rem]'
                />
                <img
                  src={images.riderUp}
                  alt='hero'
                  className='h-[9rem] w-[9rem]'
                />
              </div>
              <div className='hidden md:block bg-[#6BA740] rounded-full w-54 h-54 md:w-[20rem] md:h-[20rem] items-center justify-center'>
                <img src={images.riderduck} alt='hero' />
              </div>
              <div className='flex md:flex-col items-center justify-between md:gap-16'>
                <img
                  src={images.riderOder}
                  alt='hero'
                  className='h-9rem] w-[9rem]'
                />
                <img
                  src={images.setUp}
                  alt='hero'
                  className='h-[10rem] w-[10rem]'
                />
              </div>
            </div>
          </div>
        </div>

        <a
          href='https://play.google.com/store/apps/details?id=com.nownowdispatch'
          className='flex mt-4 md:flex-row md:mt-[5rem] justify-center items-center w-full md:h-[35rem] p-4 md:p-8 mb-10 relative'
        >
          <div className='absolute top-0 left-0 w-full h-full z-10'>
            <img
              src={images.dashboard1}
              className='absolute left-10 top-[-1rem] md:top-[4rem] md:left-40 w-32 md:w-64 h-[24rem] md:h-[36rem] object-cover md:object-contain'
              alt='dashboard'
            />
          </div>
          <div className='bg-[#C3990F] w-full h-56 md:h-[20rem] gap-10 rounded-2xl relative z-0 flex items-center justify-evenly'>
            <div className='w-full md:w-[34rem] ml-40 md:ml-0 gap-4 md:gap-10 p-2 md:p-4'>
              <h1 className='text-lg sm:text-xl md:text-4xl font-semibold md:font-bold text-primaryBlack'>
                Download our Mobile App
              </h1>
              <div className='mt-4 ml-auto md:mt-6'>
                <StoreButtons />
              </div>
            </div>
          </div>
        </a>
      </div>
      <Footer />
    </div>
  );
};

export default PartnerRider;
