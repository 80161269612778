import React from "react";
import NavBar from "../components/NavBar";
import StoreButtons from "../components/StoreButtons";
import { images } from "../Assets";
import Footer from "../components/Footer";
import { Link } from "react-router-dom";

const LandingPage = () => {
  return (
    <div className=''>
      <NavBar />

      <div className='p-4 md:p-8'>
        <div className='md:flex justify-center items-center mt-20 md:mt-10 p-4 md:px-20'>
          <div className='space-y-10 w-ful md:w-1/2 '>
            <h1 className='text-3xl md:text-5xl font-bold'>
              We are a part of your life.
            </h1>
            <span className='text-[#C3990F] text-3xl font-bold'>
              Errands: pickup, food, market, shopping in 40 minutes. {""} 🚴🏾
            </span>
            <StoreButtons />
          </div>

          <div className='flex justify-center w-full md:w-1/2 '>
            <img
              src={images.HeroImage}
              className='w-full md:w-full h-auto md:h-[60%]'
              alt='hero'
            />
          </div>
        </div>

        <div className=''>
          <h2 className='text-center font-bold text-3xl md:text-5xl text-primaryBlack'>
            How It <span className='text-[#C3990F]'>Works</span>
          </h2>
          <p className='text-primaryBlack text-center font-medium mt-4 md:w-[36rem] mx-auto leading-7 text-lg'>
            Let us know what errand you need help with, and we will dispatch
            someone to assist you based on your needs.
          </p>
          <div className='mt-10 w-full grid-cols-1 grid md:grid-cols-3'>
            <div className='flex flex-col items-center'>
              <div className='w-[80%] '>
                <img src={images.setAccount} className='' alt='Download App' />
              </div>
              <p className='text-xl font-medium mt-5 text-[#C3990F]'>
                Place an order.
              </p>
            </div>

            <div className='flex flex-col items-center'>
              <div className='w-[80%]'>
                <img src={images.rider} className='' alt='Set Up Account' />
              </div>
              <p className='text-xl font-medium mt-5 text-[#C3990F]'>
                Rider is dispatched.
              </p>
            </div>

            <div className='flex flex-col items-center'>
              <div className='w-[80%]'>
                <img src={images.delivery} className=' ' alt='Receive Orders' />
              </div>
              <p className='text-xl font-medium mt-5 text-[#C3990F]'>
                Rider delivers in 40 minutes.
              </p>
            </div>
          </div>
        </div>

        <div className='mt-10 md:flex justify-center items-center p-4 md:p-12'>
          <div className='w-full md:w-1/2'>
            <img
              src={images.supermarket}
              alt='african'
              className='w-[70%] mx-auto'
            />
          </div>

          <div className='mt-5 md:mt-0 w-full md:w-1/2'>
            <h1 className='text-2xl md:text-5xl font-bold text-primaryBlack leading-tight md:leading-normal'>
              Hassle-Free <br />
              <span className='text-[#C3990F] '>Delivery</span>
            </h1>

            <p className='text-lg font-normal text-primaryBlack w-full md:w-[80%] mt-5'>
              Join the league of satisfied NowNans who have made NowNow Dispatch
              their go-to errand service. You too can place your order and track
              its progress in real time. Be it food, market runs or any errand
              at all. Our reliable Dispatch service means you won’t wait for too
              long to get your order delivered.
            </p>
          </div>
        </div>

        <div className=' mt-20 flex flex-col md:flex-row gap-6 px-4 md:px-12'>
          <div className='w-full md:w-1/2'>
            <div className='space-y-5'>
              <h1 className='text-2xl md:text-5xl font-bold text-primaryBlack'>
                Partner With NowNow <br />
                <span className='text-[#C3990F]'>Dispatch</span>
              </h1>
              <p className='w-full md:w-[35rem] text-base font-normal text-primaryBlack '>
                Ride the NowNow wave, partner with us and showcase your business
                to new customers that will buy from you now! It’s easy.
              </p>
            </div>
            <div className='px-10'>
              <div className='w-1/2 mx-auto'>
                <img src={images.woman} className='mx-auto' alt='vendor' />
              </div>
              <div className='px-6 py-4'>
                <p className='font-semibold text-[#C3990F] text-2xl mb-2'>
                  Run errands for us {""}
                </p>
                <p className='text-primaryBlack font-medium'>
                  Partner with us and run errands for thousands of customers .
                </p>
                <div className='flex justify-end my-4'>
                  <Link to={"/contact-us"}>
                    <img src={images.Arrow1} alt='vendor' />
                  </Link>
                </div>
              </div>
            </div>
          </div>

          <div className='w-full md:w-1/2'>
            <div className='px-10'>
              <div className='w-1/2 mx-auto'>
                <img
                  src={images.riders}
                  className=' w-full mx-auto'
                  alt='rider'
                />
              </div>
              <div className='px-10'>
                <p className='font-semibold text-[#C3990F] text-2xl mb-2'>
                  Become a NowNow Angel. {""}
                </p>
                <p className='text-primaryBlack font-medium mt-5'>
                  Looking for a side hustle or full time gig? Earn good money
                  delivering errands to NowNans.
                </p>
                <div className='flex justify-end my-4'>
                  <Link to={"/earn"}>
                    <img src={images.Arrow1} alt='rider' />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>

        <a
          href='https://play.google.com/store/apps/details?id=com.nownowdispatch'
          className='flex md:flex-row  justify-center items-center w-full md:h-[35rem] p-4 md:p-8 mb-5 relative'
        >
          <div className='absolute top-0 left-0 w-full h-full z-10'>
            <img
              src={images.dashboard1}
              className='absolute left-10 top-[-1rem] md:top-[4rem] md:left-40 w-32 md:w-64 h-[24rem] md:h-[36rem] object-cover md:object-contain'
              alt='dashboard'
            />
          </div>
          <div className='bg-[#C3990F] w-full h-56 md:h-[20rem] gap-10 rounded-2xl relative z-0 flex items-center justify-evenly'>
            <div className='w-full md:w-[34rem] ml-40 md:ml-0 gap-4 md:gap-10 p-2 md:p-4'>
              <h1 className=' text-sm md:text-4xl font-semibold md:font-bold text-primaryBlack'>
                Download our Mobile App and Earn as a Rider
              </h1>
              {/* <div className='flex flex-row gap-3 md:gap-5 mt-2 md:mt-4'>
                <img
                  src={images.frame282}
                  className='w-40 h-12 md:h-16 md:w-[20rem]'
                  alt='icon1'
                />
              </div> */}
              <div className='mt-4 ml-auto md:mt-6'>
                <StoreButtons />
              </div>
            </div>
          </div>
        </a>
      </div>

      <Footer />
    </div>
  );
};

export default LandingPage;
